import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--chevron';
const ICON_ID = 'chevron';

const IconChevron = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 9 14" iconId={ICON_ID} />
);

IconChevron.propTypes = {
  className: string,
};

export default IconChevron;
