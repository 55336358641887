import React from 'react';
import { bool, element, string } from 'prop-types';

const BuildIcon = ({
  children,
  className,
  color,
  fill,
  viewBox = '',
  iconId = '',
  dataTestId,
  ariaHidden,
  description,
  srLabel,
  fillOpacity,
  roleIcon,
  id,
  style,
  height,
  width,
}) => (
  <svg
    className={className}
    viewBox={viewBox}
    fill={fill ?? color ?? 'none'}
    data-testid={iconId ? dataTestId ?? `icon-${iconId}` : null}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={ariaHidden}
    aria-label={description ?? srLabel}
    fillOpacity={fillOpacity}
    role={roleIcon}
    id={id}
    style={style}
    height={height}
    width={width}
    key={iconId}
  >
    {children ?? <use fill={color} href={`#${iconId}`} />}
  </svg>
);

BuildIcon.propTypes = {
  ariaHidden: bool,
  children: element,
  className: string,
  color: string,
  dataTestId: string,
  description: string,
  fill: string,
  fillOpacity: string,
  height: string,
  iconId: string,
  id: string,
  roleIcon: string,
  srLabel: string,
  style: string,
  viewBox: string,
  width: string,
};

export default React.memo(BuildIcon);
