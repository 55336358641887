import React from 'react';
import { bool, string } from 'prop-types';

import { Image } from 'nordic/image';

const BuildIconImage = ({
  className,
  src,
  description,
  srLabel,
  lazyload,
  iconId,
  preload = false,
  style,
  width,
  height,
}) => {
  if (!src && !description) {
    return null;
  }

  const obj = () => (
    <Image
      src={src}
      alt={description ?? srLabel}
      className={className}
      lazyload={lazyload ? 'on' : 'off'}
      preload={preload}
      id={iconId}
      style={style}
      width={width}
      height={height}
    />
  );

  obj.ICON_ID = iconId;

  return obj();
};

BuildIconImage.propTypes = {
  className: string,
  description: string,
  height: string,
  iconId: string,
  lazyload: bool,
  preload: bool,
  src: string,
  srLabel: string,
  style: string,
  width: string,
};

export default React.memo(BuildIconImage);
